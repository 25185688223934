import { FaHandshake } from "react-icons/fa6";
import { FaStethoscope } from "react-icons/fa";
import { FaBalanceScale } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { GoGraph } from "react-icons/go";

export const AboutData = [
    {
      icon: <FaHandshake className="handshake-icon" size={36} color="1977cc" />,
      title: "Compassion",
      description:
        "Welcome to Family Hospital where compassionate care meets healing expertise. Our dedicated team of healthcare professionals is committed to providing personalized andempathetic medical services. Discover a place where your well-being is our priority and experience healthcare with a heart.",
    },
    {
      icon: <FaStethoscope size={36} color="1977cc" />,
      title: "Quality Policy",
      description:
        "At Family Hospital, we are dedicated to upholding the highest standards of quality in healthcare. Our commitment is rooted in providing patient-centered services that prioritize safety, effectiveness and compassion. We continually strive for excellence through ongoing training, advanced technology and adherence to regulatory requirements. Your well being is ourpriority and we pledge to deliver care that is both reliable and compassionate. Through continuous improvement and a patient-focused approach, we aim to be your trusted partne health, dedicated to providing quality care that exceeds expectations.",
    },
    {
      id: "tab-3",
      icon: <FaBalanceScale size={36} color="1977cc" />,
      title: "Integrity",
      description: "",
    },
    {
      icon: <FaPeopleGroup size={36} color="1977cc" />,
      title: "Teamwork",
      description:
        "At Family Hospital, we believe in the power of teamwork to enhance patient care. Our collaborative approach involves a diverse team of skilled medical professionals working seamlessly to provide comprehensive healthcare solutions. From doctors and nurses to support staff, we unite our strengths to ensure your well-being is at the forefront of every excellence,embracing a culture of cooperation that fosters a healing environment for all.",
    },
    {
      icon: <GoGraph size={36} color="1977cc" />,
      title: "Continuous Improvement",
      description:
        "",
    },
  ];