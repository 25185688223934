import './App.css';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/MainHero/Hero';
import About from './components/About/About';
import Service from './components/Service/Service';
import Appointment from './components/Appointment/Appointment';
import Department from './components/Department/Department';
import Doctors from './components/Doctors/Doctors';
// import FAQs from './components/FAQs/FAQs';
import Testimonials from './components/Testimonials/Testimonials';
import Gallery from './components/Gallery/Gallery';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
   <> <Navbar/>
   <Hero/>
   {/* <BrowserRouter> 
   <Routes> 
  
    <Route path = "/" element = {<Navbar/>}/>
    <Route path = "/" element = {<Hero/>}/>
    <Route path = "/about" element = {<About/>}/>
    <Route path = "/services" element = {<Service/>}/>
    <Route path = "/appointment" element = {<Appointment/>}/>
    <Route path = "/department" element = {<Department/>}/>
    <Route path = "/doctors" element = {<Doctors/>}/>
    <Route path = "/FAQs" element = {<FAQs/>}/>
    <Route path = "/testimonials" element = {<Testimonials/>}/>
    <Route path = "/gallery" element = {<Gallery/>}/>
    <Route path = "/contact" element = {<Contact/>}/>
    <Route path = "/footer" element = {<Footer/>}/> */}
   
    
    <About/>
    <Service/>
    <Appointment/>
    <Department/>
    <Doctors/>
    {/* <FAQs/> */}
    <Testimonials/>
    <Gallery/>
    <Contact/>
    <Footer/>
  {/* </Routes>
  </BrowserRouter> */}
   </>
    
  );
}

export default App;

