import React from 'react'
import { FaHandHoldingMedical } from "react-icons/fa";
import { FaHospitalUser } from "react-icons/fa6";
import { FaBedPulse } from "react-icons/fa6";
import { FaHandsHoldingChild } from "react-icons/fa6";
import { FaHeartCircleCheck } from "react-icons/fa6";
import { FaBed } from "react-icons/fa";
import './Service.css'
import Navbar from '../Navbar/Navbar';
import Appointment from '../Appointment/Appointment';

const Service = () => {
  return (
    <div>
      {/* <Navbar/> */}
      {/* <!-- ======= Services Section ======= --> */}
      <section id="services" class="services">
        <div class="container">
          <div class="section-title">
            <h2>Services</h2>
            <p>
              All these services are rendered to enable patients enjoy a healthy
              life style where they feel no hesitationin displaying their
              beautiful smile.
            </p>
          </div>

          <div class="row">
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="icon-box">
                <div class="icon">
                  <FaHandHoldingMedical size={36} color="white" />
                </div>
                <h4>
                  <a href="">Emergency Care</a>
                </h4>
                <p>
                  Welcome to <b>"Family Hospital"</b> Emergency Care Facility
                </p>
                <h5>Emergency Services</h5>
                <p>
                  Our dedicated team is equipped to handle a wide range of
                  emergencies including medical, trauma and critical care
                  situations. We are committed to providing prompt and
                  compassionate care.
                </p>
                <h5>Contact Information</h5>
                <p>
                  In case of an emergency, call our 24/7. You can reach us at{" "}
                  <b>(9310206503, 8448610029)</b> or email us at{" "}
                  <b>familyhospital18@gmail.com</b> Visit us at{" "}
                  <b>
                    WZ 26A, New Sahib Pura, Opp. C.R.P.F Camp Tilak Nagar, New
                    Delhi, India, 110018
                  </b>
                </p>
                <p>
                  <b>Our Team-</b>
                  <span>
                    Meet our experienced and highly qualified medical
                    professionals who are ready to respond swiftly to your
                    needs.
                  </span>
                </p>
                <p>
                  <b>Facilities and Equipment-</b>
                  <span>
                    Meet Our state of the art facilities are equipped with
                    advanced medical technology, including emergency rooms,
                    critical care units and surgical suits. We prioritize
                    maintaining a cutting edge environment to provide optimal
                    care.
                  </span>
                </p>
                <p>
                  <b>What to Do in an Emergency-</b>
                  <span>
                    Learn when and how to seek emergency care, basic first and
                    tips, and critical information on handling specific
                    emergencies. Your safety is our priority, and being informed
                    can make a significant difference.
                  </span>
                </p>
                <p>
                  <b>Insurance and Billing-</b>
                  <span>
                    We accept a variety of insurance plans to make emergency
                    care accessible. Details on our accepted insurance, billing
                    procedures and any financial assistance options available.
                  </span>
                </p>
                <p>
                  <b>Patient Stories-</b>
                  <span>
                    Read about the positive experiences of individuals who
                    received emergency care at our facility. Real stories from
                    real people highlight our commitment to excellence in
                    healthcare.
                  </span>
                </p>
                <p>
                  <b>Community Resources-</b>
                  <span>
                    Explore local emergency services, community organizations,
                    and support groups. We believe in building a network that
                    enhances overall community safety and well being.
                  </span>
                </p>
                <p>
                  <b>Educational Resources-</b>
                  <span>
                    Access informative materials including videos brochures and
                    articles covering a range of topics related to emergencies.
                    Knowledge empowers individuals to make informed decisions
                    during critical moments.
                  </span>
                </p>
                <p>
                  <b>“Latest Updates”</b> stay informed about the latest news,
                  events and advancements in emergency medical care. Regular
                  updates ensure you are aware of any changes or improvements in
                  our services.
                </p>
                <p>
                  We are here for you when seconds count. Trust (your emergency
                  care facility) for expert, compassionate and efficient
                  emergency care
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
              <div class="icon-box">
                <div class="icon">
                  <FaBedPulse size={36} color="white" />
                </div>
                <h4>
                  <a href="">Inpatient Care</a>
                </h4>
                <p>
                  Welcome to <b>Family Hospital (Inpatient Care Facility)</b>
                </p>
                <p>
                  <b>Inpatient Services-</b>
                  <span>
                    Our Inpatient Care services are designed to provide
                    comprehensive medical care and support for individuals
                    requiring hospitalization. We prioritize your comfort,
                    well-being and recovery.
                  </span>
                </p>
                <p>
                  <b>Admission Process-</b>
                  <span>
                    Learn about the seamless admission process at your facility
                    from initial assessments to personalized care plans. Our
                    team is dedicated to making care as smooth as possible.
                  </span>
                </p>
                <p>
                  <b>Specialized Units-</b>
                  <span>
                    Explore our specialized inpatient units each equipped to
                    address specific medical needs. From medical surgical to
                    intensive care units, we tailor our approach to meet the
                    unique requirements of our patients.
                  </span>
                </p>
                <p>
                  <b>Patient Rooms-</b>
                  <span>
                    Experience comfort and convenience in our appointed patient
                    rooms. We understand the importance of a healing environment
                    and our rooms are designed to promote rest and recovery.
                  </span>
                </p>
                <p>
                  <b>Medical Staff-</b>
                  <span>
                    Meet our team of experienced and compassionate healthcare
                    professionals who will be overseeing your care. We believe
                    in a collaborative approach, ensuring that you receive the
                    highest standard of medical attention.
                  </span>
                </p>
                <p>
                  <b>Treatment Plans-</b>
                  <span>
                    Discover how we create personalized treatment plans for each
                    patient, integrating medical expertise with individuals
                    needs. Your care plan is tailored to optimize your recovery
                    journey.
                  </span>
                </p>
                <p>
                  <b>Family Support-</b>
                  <span>
                    At (Your Facility) we recognize the vital role families play
                    in the healing process. Learn about our family support
                    services and facilities ensuring a supportive environment
                    for both patients and their loved ones.
                  </span>
                </p>
                <ul>
                  <h5>Therapy and Rehabilitation</h5>
                  <li>Nutrition and Wellness</li>
                  <li>Visitor Information</li>
                  <li>Insurance and Billing</li>
                  <li>Patient Testimonials</li>
                </ul>
                <p>
                  Latest Update- Stay informed about the latest developments,
                  advancements and additions to our inpatient care services. We
                  are committed to continuously improving and expanding
                  offerings.
                </p>
                <p>
                  At (Your Inpatient Care Facility) we are dedicated to
                  providing exceptional care that promotes healing and well
                  being. Trust for your inpatient care needs
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="icon-box">
                <div class="icon">
                  <FaHeartCircleCheck size={36} color="white" />
                </div>
                <h4>
                  <a href="">Diagnostic Services</a>
                </h4>
                <p>
                  Welcome to <b>Family Hospital</b> where advanced diagnostics
                  converge with compassionate care. Our dedicated team of
                  medical professionals utilizes state of the art technology to
                  offer a comprehensive range of diagnostic services. From
                  imaging and laboratory tests to specialized procedures, we are
                  committed to providing accurate and timely results to guide
                  your treatment journey.
                </p>
                <ul>
                  <h4>Our Diagnostic Services Include</h4>
                  <h5>Imaging Services</h5>
                    <li>High-resolution X-rays</li>
                    <li>MRI Scan for detailed imaging</li>
                    <li>CT Scans for precise diagnostics</li>
                    <li>Ultrasound for non invasive assessments</li>
                  <h5>Laboratory Services</h5>
                    <li>Blood tests for a wide range of conditions</li>
                    <li>Pathology services for accurate diagnosis</li>
                    <li>Microbiology tests for infectious diseases</li>
                  <h5>Cancer Screenings</h5>
                    <li>Mammography for breast health</li>
                    <li>Pap smears for cervical cancer detection</li>
                    <li>Colonoscopies for colorectal health</li>
                  <h5>Specialized Procedures</h5>
                    <li>Endoscopy for digestive system evaluation</li>
                    <li>Pulmonary function tests for respiratory health</li>
                    <li>Neurological diagnostics for brain and nerve disorders</li>
                </ul>
                <p>At <b>Family Hospital</b> we understand the importance of swift and precise diagnostics in your
healthcare journey. Our commitment to excellence ensures that you receive the highest
standard of care in a patient friendly environment. Trust us for accurate results and
compassionate support. Your health is our priority</p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div class="icon-box">
                <div class="icon">
                  <FaHospitalUser size={36} color="white" />
                </div>
                <h4>
                  <a href="">Outpatient Services</a>
                </h4>
                <p>
                  Welcome to <b>Family Hospital</b> outpatient services, we
                  pride ourselves on delivering exceptional outpatient care,
                  combining expertise, compassion and cutting-edge technology to
                  meet your healthcare needs. Our comprehensive range of
                  outpatient services is designed with your well- being in mind.
                </p>
                <h5>Explore our Outpatient Offerings</h5>
                <ul>
                  <li>Specialized clinics</li>
                  <li>Diagnostic Precision</li>
                  <li>Day Surgery Excellence</li>
                  <li>Rehabilitation Services</li>
                  <li>Preventive Care Initiatives</li>
                </ul>
                <h5>Why Choose Family Hospital for Outpatient Care?</h5>
                <ul>
                  <li>Patient Centric Philosophy</li>
                  <li>Expert Care team</li>
                  <li>State of the art facilities</li>
                  <li>Efficient and timely services</li>
                </ul>
                <p>
                  Choose <b>Family Hospital</b> for a seamless and personalized
                  outpatient experience. Your health journey starts with us.
                </p>
                <p>Empowering health Enhancing lives</p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div class="icon-box">
                <div class="icon">
                  <FaHandsHoldingChild size={36} color="white" />
                </div>
                <h4>
                  <a href="">Primary Care</a>
                </h4>
                <p>
                  Welcome to Your Primary Care Center where your health takes
                  center stage. Our dedicated team of healthcare professionals
                  is committed to providing personalized and comprehensive
                  primary care services for individuals and families.
                </p>
                <ul>
                  <h5>Services We Offer</h5>
                  <li>Wellness Check-ups</li>
                  <li>Preventive Care</li>
                  <li>Chronic Diseases Management</li>
                  <li>Pediatric Care</li>
                  <li>Women’s Health</li>
                  <li>Men’s Health</li>
                  <li>Geriatric Care</li>
                  <li>Minor Procedures</li>
                </ul>
                <p>
                  Our commitment is to foster a trusting and collaborative
                  relationship with our patients. At
                  <b>"Family Hospital"</b> we prioritize your health and
                  well-being, providing the support you need to lead a healthy
                  and fulfilling life. Schedule your appointment today, and
                  let’s embark on your journey to optimal health together.
                </p>
              </div>
            </div>

            

            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div class="icon-box">
                <div class="icon">
                  <FaBed size={36} color="white" />
                </div>
                <h4>
                  <a href="">Surgical Services</a>
                </h4>
                <p>
                  Welcome to our <b>"Family Hospital"</b> Surgical Services. Our
                  commitment to exceptional surgical care is at the heart of our
                  mission. We understand that each patient is unique and a
                  dedicated team of skilled surgeons is here to provide services
                  across the spectrum of specialties.
                </p>
                <ul>
                  <h5>
                    Why Choose <b>"Family Hospital"</b>
                  </h5>
                  <li>Expert Surgeons</li>
                  <li>State of the art Facilities</li>
                  <li>Comprehensive Specialities</li>
                  <li>Minimally Invasive</li>
                  <li>Patient-Centered Approach</li>
                </ul>
                <ul>
                  <h5>Our Surgical Services Include</h5>
                  <li>General Surgery</li>
                  <li>Orthopedic Surgery</li>
                  <li>Cardiothoracic Surgery</li>
                  <li>Gastrointestinal Surgery</li>
                  <li>Minimally Invasive Procedures</li>
                </ul>
                <h5>Patient Centered Care</h5>
                <p>
                  Your health is our priority. We work collaboratively with you
                  and your healthcare team to ensure a seamless surgical
                  experience from pre-operative preparation to post-operative
                  care.
                  <br />
                  Contact Us Ready to take the next step in your healthcare
                  journey? Contact us today to schedule a consultation or learn
                  more about our surgical services. We are here to support you
                  every step of the way.
                  <br />
                  Trust in <b>"Family Hospital"</b> surgical services for
                  excellence in care and a commitment to your health and well
                  being
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section --> */}

      {/* <Appointment/> */}
    </div>
  );
}

export default Service