import React from 'react'
import './Gallery.css'
import Navbar from '../Navbar/Navbar'
import Contact from '../Contact/Contact'

const Gallery = () => {
  return (
    <div>
         {/* <!-- ======= Gallery Section ======= --> */}
         {/* <Navbar/> */}
      <section id="gallery" class="gallery">
        <div class="container">
          <div class="section-title">
            <h2>Gallery</h2>
            <p>
              Explore Our Gallery Images, a Tapestry of Captivating Moments and
              Stunning Visuals. Immerse Yourself in a Pictorial Journey of
              Inspiration and Creativity.
            </p>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row g-0">
            <div class="col-lg-4 col-md-4">
              <div class="gallery-item">
                <a
                  href="/gallery/gallery-1.jpg"
                  class="galelry-lightbox"
                    target="_blank"
                >
                  <img
                    src="/gallery/gallery-1.jpg"
                    alt=""
                  
                    class="img-fluid"
                  />
                </a>
              </div>
            </div>

            <div class="col-lg-4 col-md-4">
              <div class="gallery-item">
                <a
                  href="/gallery/gallery-8.jpg"
                  class="galelry-lightbox"
                  target='_blank'

                >
                  <img
                    src="/gallery/gallery-8.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </a>
              </div>
            </div>

            <div class="col-lg-4 col-md-4">
              <div class="gallery-item">
                <a
                  href="/gallery/gallery-4.jpg"
                  class="galelry-lightbox"
                  target="_blank"
                >
                  <img
                    src="/gallery/gallery-4.jpg"
                    alt=""
                    target="_blank"
                    class="img-fluid"
                  />
                </a>
              </div>
            </div>

            <div class="col-lg-4 col-md-4">
              <div class="gallery-item">
                <a
                  href="/gallery/gallery-2.jpg"
                  class="galelry-lightbox"
                     target="_blank"
                >
                  <img
                    src="/gallery/gallery-2.jpg"
                    alt=""
                 
                    class="img-fluid"
                  />
                </a>
              </div>
            </div>

            <div class="col-lg-4 col-md-4">
              <div class="gallery-item">
                <a
                  href="/gallery/gallery-3.jpg"
                  class="galelry-lightbox"
                    target="_blank"
                >
                  <img
                    src="/gallery/gallery-3.jpg"
                    alt=""
                  
                    class="img-fluid"
                  />
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 ">
              <div class="gallery-item">
                <a
                  href="/gallery/hospital_1.png"
                  class="galelry-lightbox"
                >
                  <img
                    src="/gallery/hospital_1.png"
                    alt=""
                    class="img-fluid "
                    className='image-size'
                  />
                </a>
              </div>
            </div> 

             <div class="col-lg-4 col-md-4">
              <div class="gallery-item">
                <a
                  href="/gallery/hospital_2.png"
                  class="galelry-lightbox"
                >
                  <img
                    src="/gallery/hospital_2.png"
                    alt=""
                    class="img-fluid "
                    className='image-size'
                  />
                </a>
              </div>
            </div> 

            {/* <div class="col-lg-4 col-md-4">
              <div class="gallery-item">
                <a
                  href="/gallery/gallery-7.jpg"
                  class="galelry-lightbox"
                  target='_blank'
                >
                  <img
                    src="/gallery/gallery-7.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </a>
              </div>
            </div> */}

            
          </div>
        </div>
      </section>
      {/* <Contact/> */}
      {/* <!-- End Gallery Section --> */}
    </div>
  )
}

export default Gallery