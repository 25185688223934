

export const Psychiatric = {
  id: "tab-5",
  title: "Psychiatric",
  data: [
    
    {
      heading: "Our Approach:",
      content:
        "At Family Hospital, we understand the unique nature of mental health and tailor our approach to meet individual needs. Our team of experienced psychiatrists, psychologists and mental health professionals collaborate to create personalized treatment plans.",
    },
    {
      heading: "Service We Offer:",
    },
    {
      heading: "Psychiatric Assessments:",
      content:
        "Thorough evaluations to understand your unique needs.",
    },
    {
      heading: "Inpatient Care:",
      content:
        "Safe and Supportive environments for their requiring intensivetreatment.",
    },
    {
      heading: "Outpatient Services:",
      content:
        "Flexible options for ongoing care and therapy sessions.",
    },
    {
      heading: "Therapeutic Modalities:",
      content:
        "Evidence based treatments including psychotherapy medication management and holistic therapies.",
    },
    {
      heading: "Patient Centered Care:",
      content:
        "We prioritize open communication and involve patients in their care journey. Our goal is to empower individuals to actively participate in decisions about their mental health treatment.",
    },
    {
      heading: "Inpatient Care:",
      content:
        "Safe and Supportive environments for their requiring intensivetreatment.",
    },
    {
      heading: "Holistic Wellness:",
      content:
    "Our approach extends beyond symptom management to promote overall well-being. We integrate holistic practices that address physical, emotional and social aspects of health.",
    },
    {
      heading: "Confidentiality and Respect:",
      content:
        "Your privacy is paramount. Our team upholds the highest standards of confidentiality and treats every individual with respect fostering a stigma - free environment.",
    },
    {
      heading: "Why Choose I Family Hospital?",
      content:
        "",
    },
  ],
};

