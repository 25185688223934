import React, { useState } from "react";
import "./Department.css";
import Heading from "./Heading";
import { Pediatrics } from "./Pediatrics";
import Content from "./Content";
import { Link } from "react-scroll";
import { Psychiatric } from "./Psychiatric";
import Icon from "../Icon/Icon";

const Department = () => {
  const departmentData = [
    {
      id: "tab-1",
      title: "Medicine",
      heading: "Medicine",
      content: "",
      image: "/departments-1.jpg",
      mainContent:
        "Welcome to our Medicine Hub, where we prioritize your well being. Explore articles on health, wellness and the latest medical breakthroughs. From expert advice to in depth research, we’re your reliable source for all things health related. Stay informed Stay healthy.",
    },
    {
      id: "tab-2",
      // title: 'Gynecologist',
      heading: "Gynecologist",
      //content: '',
      image: "",
      //mainContent: 'Welcome to our “Family Hospital” a dedicated space where we prioritize the health and well being of your child. Our team of compassionate pediatricians and health care professionals is committed to providing comprehensive and personalized care for children of all ages.',
    },
    {
      id: "tab-3",
      //title: 'Orthopedic',
      heading: "Orthopedic",
      //content: '',
      image: "",
      //mainContent: 'Welcome to our “Family Hospital” a dedicated space where we prioritize the health and well being of your child. Our team of compassionate pediatricians and health care professionals is committed to providing comprehensive and personalized care for children of all ages.',
    },
    {
      id: "tab-4",
      //title: 'Pediatrics',
      heading: "Pediatrics",
      //content: '',
      image: "",
      //mainContent: 'Welcome to our “Family Hospital” a dedicated space where we prioritize the health and well being of your child. Our team of compassionate pediatricians and health care professionals is committed to providing comprehensive and personalized care for children of all ages.',
    },
    {
      id: "tab-5",
      title: "ENT Care and Surgery",
      heading: "ENT Care and Surgery",
      content: "",
      image: "/departments-4.jpg",
      mainContent:
        "ENT Care involves the diagnosis and treatment of conditions related to the ears, nose and throat common issues include ear infections, sinus problems and throat disorders. Surgery may be recommended for certain cases, such tonsillectomy or sinus surgery. Consultation with an ENT Specialist is crucial for personalized advice based on your specific symptoms. Surgery may be considered for issues like nasal polyps, deviated septum or ear problems. If you’re experiencing symptoms. It is advisable to consult an ENT specialist for a thorough evaluation and appropriate guidance.",
    },
    {
      id: "tab-6",
      title: "Neurology",
      heading: "Neurology",
      content: "",
      image: "/departments-2.jpg",
      mainContent:
        "Welcome to our Neurology Hub where we explore the intricate realm of the human nervous system. Drive into articles covering brain functions, disorders and the latest breakthroughs in neurological research. Discover insights into conditions like Alzheimer’s Parkinson’s and epilepsy  Stay informed about cutting edge treatments and technologies shaping the future of neurology. Join us on a journey through the harinating world of the brain, where science meets the complexities of our most intricate organ.",
    },
    {
      id: "tab-7",
      //title: 'Psychiatric Care',
      heading: "Psychiatric Care",
      // content: '',
      image: "",
      // mainContent: 'Welcome to Family Hospital, your trusted partner in mental health and well being. OurPsychiatric Care unit is committed to providing comprehensive and compassionate  support for individuals facing mental health challenge.',
    },
  ];

  const [activeTab, setActiveTab] = useState(departmentData[0].id);
  //const [pediatricsTab , setPediatricsTab] = useState(departmentDataPediatrics[0].id)

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div>
      <section id="departments" className="departments">
        <div className="container">
          <div className="section-title">
            <h2>Departments</h2>
            <p>Navigating the Specialized Realms of Hospital Departments...</p>
          </div>

          <div className="row gy-4">
            <div className="col-lg-3">
              <ul className="nav nav-tabs flex-column">
                {departmentData.map((department) => (
                  <li key={department.id} className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === department.id ? "active show" : ""
                      }`}
                      onClick={() => handleTabChange(department.id)}
                    >
                      {department.heading}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-lg-9">
              <div className="tab-content">
                {departmentData.map((department) => (
                  <div
                    key={department.id}
                    className={`tab-pane ${
                      activeTab === department.id ? "active show" : ""
                    }`}
                    id={department.id}
                  >
                    <div className="row gy-4">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>{department.title}</h3>
                        <p className="" id="h3">
                          {department.content}
                        </p>
                        <p>{department.mainContent}</p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img
                          src={`${department.image}`}
                          alt={department.title}
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                ))}
                {/* Pediatrics data */}
                {/* <> */}
                {/* {Pediatrics.data.map((departmentData) => (
                    <div
                      key={departmentData.id}
                      className={`tab-pane ${
                        activeTab === Pediatrics.id ? "active show" : ""
                      }`}
                      id={departmentData.id}
                    >
                      <Heading heading={departmentData.heading} />
                      <Content para={departmentData.content} />
                    </div>
                  ))} */}
                {activeTab === "tab-4" ? (
                  <>
                    <div className="row gy-4">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>Pediatric</h3>
                        <h4>Your Child’s Health Our Priority</h4>
                        <p>
                          Welcome to our <b>“Family Hospital”</b> a dedicated
                          space where we prioritize the health and well being of
                          your child. Our team of compassionate pediatricians
                          and health care professionals is committed to
                          providing comprehensive and personalized care for
                          children of all ages.
                        </p>
                        <h4>Our Services:</h4>
                        <p>
                          Well baby and child check-up From newborn to
                          adolescents offer regular checkups to monitor your
                          child's growth development and overall health. Our
                          goal is to ensure every child reaches their full
                          patients.
                        </p>
                        <h4>Vaccinations and Immunizations: </h4>
                        <p>
                          stay on to pay your child’s Immunizations schedule
                          with our expert guidance. Provide a safe and
                          supportive environment for vaccinations, protect your
                          child against preventable diseases.{" "}
                        </p>

                        <h4>Pediatric Urgent Care:</h4>
                        <p>
                          For unexpected health concerns,our pediatric urgent
                          care services are available to address your child’s
                          needs promptly ensuring peace of mind for parents.
                        </p>
                        <h4>Developmental Assessments:</h4>
                        <p>
                          Our team conducts through developmental assessments to
                          identify and address any developmental concerns early.
                          fostering a healthy and supportive environment for
                          your child’s growth.
                        </p>
                        <h4>Pediatric Specialties:</h4>
                        <p>
                          We specialize in various pediatric areas, including
                          pediatric cardiology, palmonology, gastroenterology
                          and more ensuring comprehensive care tailored to your
                          child’s unique needs.
                        </p>
                        <h4>MEET OUR PEDIATRICIANS</h4>
                        <p>
                          <b>
                            Dr. Paras Dev MD (Physician) DCH Child specialist,
                            Regd No. 35021(DMC)
                          </b>
                        </p>
                        <p>
                          Leading our team of dedicated pediatricians,{" "}
                          <b> Dr. Sharvari Gupta - MD (Pediatric) DM 25903 </b>{" "}
                          is a board certified professional with years of
                          experience specializing in specific areas of
                          expertise.
                        </p>
                        <h3>Patient and Parent Resources</h3>
                        <h4>Parenting Tips and Advice</h4>
                        <p>
                          Explore our online resources with valuable parenting
                          tips, advice on child nutrition, and information on
                          navigating various stages of childhood
                        </p>
                        <h4>Pediatric Health Blog</h4>
                        <p>
                          Stay informed about the latest pediatric health
                          topics, parenting trends and updates from our
                          Pediatric Experts.{" "}
                        </p>
                        <h4>Contact Us</h4>
                        <p>
                          For all your child’s healthcare needs, Family Hospital
                          is here for you. Schedule an appointment or reach out
                          with questions or concerns.
                        </p>
                        <h4>Stay Connected</h4>
                        <p>
                          Connect with us on Social Media for updates, parenting
                          tips and community events dedicated to promoting the
                          health and well being of our child.{" "}
                        </p>
                        <p>
                          At our <b>“Family Hospital''</b>, we believe in
                          fostering a healthy start for every child. Trust us to
                          be your partner in nurturing your child's well being
                          from infancy through adolescence.{" "}
                        </p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img
                          src=".\departments-3.jpg"
                          alt="image"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <Link
                      to="appointment"
                      className="appointment-btn scrollto"
                      smooth={true}
                      duration={50}
                    >
                      <span className="d-none d-md-inline">Make an</span>{" "}
                      Appointment
                    </Link>
                  </>
                ) : null}
                {/* Psychiatric */}
                {/* </> */}

                {/* <>
                  {Psychiatric.data.map((departmentData_2) => (
                    <div
                      key={departmentData_2.id}
                      className={`tab-pane ${
                        activeTab === Psychiatric.id ? "active show" : ""
                      }`}
                      id={departmentData_2.id}
                    >
                      <Heading heading={departmentData_2.heading} />
                      <Content para={departmentData_2.content} />
                    </div>
                  ))} */}
                {activeTab === "tab-7" ? (
                  <>
                    <div className="row gy-4">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>Psychiatric Care</h3>
                        <p>
                          Welcome to <b>Family Hospital</b>, your trusted
                          partner in mental health and well being. Our
                          Psychiatric Care unit is committed to providing
                          comprehensive and compassionate  support for
                          individuals facing mental health challenge
                        </p>
                        <h4>Our Approach</h4>
                        <p>
                          At <b>Family Hospital</b>, we understand the unique
                          nature of mental health and tailor our approach to
                          meet individual needs. Our team of experienced
                          psychiatrists, psychologists and mental health
                          professionals collaborate to create personalized
                          treatment plans.
                        </p>
                        <h4>Service We Offer</h4>
                        <ul>
                          <li>
                            <b>Psychiatric Assessments:</b>
                            <span>
                              Thorough evaluations to understand your unique
                              needs.
                            </span>
                          </li>
                          <li>
                            <b>Inpatient Care:</b>
                            <span>
                              Safe and Supportive environments for their
                              requiring intensive treatment.
                            </span>
                          </li>
                          <li>
                            <b>Outpatient Services:</b>
                            <span>
                              Flexible options for ongoing care and therapy
                              sessions.
                            </span>
                          </li>
                          <li>
                            <b>Therapeutic Modalities:</b>
                            <span>
                              Evidence based treatments including psychotherapy
                              medication management and holistic therapies.
                            </span>
                          </li>
                        </ul>
                        <h4>Patient Centered Care:</h4>
                        <p>
                          We prioritize open communication and involve patients
                          in their care journey. Our goal is to empower
                          individuals to actively participate in decisions about
                          their mental health treatment.
                        </p>
                        <h4>Holistic Wellness:</h4>
                        <p>
                          Our approach extends beyond symptom management to
                          promote overall well-being. We integrate holistic
                          practices that address physical, emotional and social
                          aspects of health.
                        </p>
                        <h4>Confidentiality and Respect:</h4>
                        <p>
                          Your privacy is paramount. Our team upholds the
                          highest standards of confidentiality and treats every
                          individual with respect fostering a stigma - free
                          environment.
                        </p>
                        <h4>Why Choose I Family Hospital?</h4>
                        <ul>
                          <li>
                            Safe and Supportive environments for their requiring
                            intensivetreatment
                          </li>
                          <li>Cutting Edge treatment modalities</li>
                          <li>Commitment to patient-centered care</li>
                          <li>Holistic approach to mental health</li>
                          <li>Focus on privacy and respect</li>
                        </ul>
                        <p>
                          Take the first step towards mental wellness with{" "}
                          <b>Family Hospital.</b> Your journey to recovery
                          begins here
                        </p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img
                          src="/departments-5.jpg"
                          alt="image"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </>
                ) : null}

                {/* Gynecologist */}
                {activeTab === "tab-2" ? (
                  <>
                    <div className="row gy-4">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>Gynecologist</h3>
                        <p>
                          Women’s Health Excellence at <b>“Family Hospital”</b>
                        </p>
                        <p>
                          Welcome to <b>“Family Hospital”</b> where we are
                          dedicated to providing exceptional and compassionate
                          gynecological care to women of all ages. The
                          Gynecology Department is committed to prioritizing
                          your health and well being through comprehensive
                          services and a patient-centered approach.
                        </p>
                        <h4>Comprehensive Gynecological Services</h4>
                        <ul>
                          <li>Well-women Exams</li>
                          <li>Maternity and Obstetrics Care</li>
                          <li>Family Planning and Fertility Services</li>
                          <li>Menopause Management</li>
                          <li>Adolescent Gynecology</li>
                          <li>Pelvic Health and Urogynecology</li>
                        </ul>
                        <h4>Patient-Centered Approach:</h4>
                        <p>
                          At <b>“Family Hospital”</b>, we understand the
                          importance of personalized care. Our Gynecologist
                          takes the time to listen, educate and collaborate with
                          you to create a healthcare plan that meets your
                          individual needs.
                        </p>
                        <h4>Meet Our Expert Team:</h4>
                        <p>
                          Get to know the experienced and compassionate
                          professionals in our Gynecology Department. Meet our
                          team here.
                        </p>
                        <h4>Innovative Technology:</h4>
                        <p>
                          We prioritize staying at the forefront of medical
                          advancements. Our state of the act facilities and
                          cutting-edge technology ensure that you receive the
                          highest quality care.
                        </p>
                        <h4>Schedule Your Appointment</h4>
                        <p>
                          Take control of your health by scheduling an
                          appointment with our Gynecology Department. Your
                          journey optimal well-being begins at{" "}
                          <b>“Family Hospital.”</b>
                        </p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img
                          src="/departments-6.jpg"
                          alt="image"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </>
                ) : null}

                {/* Orthopedic */}
                {activeTab === "tab-3" ? (
                  <>
                    <div className="row gy-4">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>Orthopedic</h3>
                        <p>
                          Welcome to <b>“Family Hospital”</b> Orthopedic Care
                          Center
                        </p>
                        <p>
                          At Family Hospital we take pride in offering
                          world-class orthopedic services that prioritize your
                          well-being. Our dedicated team of orthopedic
                          specialists is committed to delivering the highest
                          quality care, utilizing cutting-edge technology and
                          compassionate approaches to address a wide range
                          musculoskeletal conditions.
                        </p>
                        <h4>Why Choose Family Hospital for Orthopedic Care?</h4>
                        <ul>
                          <li>Expert Orthopedic Team</li>
                          <li>Advanced Treatment Options</li>
                          <li>Patient-Centered Approach</li>
                        </ul>
                        <h4>Our Orthopedic Services:</h4>
                        <ul>
                          <li>Joint Replacement</li>
                          <li>Spine Care</li>
                          <li>Sports Medicine</li>
                          <li>Trauma and Fracture Care</li>
                          <li>Arthroscopic Procedures</li>
                        </ul>
                        <h4>Contact Us:</h4>
                        <p>
                          Ready to take the first step toward improved
                          musculoskeletal health? Contact our Orthopedic Care
                          Center at Family Hospital for appointments, inquiries
                          or emergency assistance. We’re here to guide you on
                          your journey to a healthier, more active life..
                        </p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img
                          src="/departments-7.jpg"
                          alt="image"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {/* </> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Department;
