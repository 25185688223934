import React from 'react'
import './Hero.css';
import { FaRegSquareCheck } from "react-icons/fa6";
import { IoIosCube } from "react-icons/io";
import { ImImages } from "react-icons/im";
import About from '../About/About';
import Navbar from '../Navbar/Navbar';

const Hero = () => {
  return (
    <div>
      {/* <Navbar/> */}

      <section id="hero" class="d-flex align-items-center">
        <div class="container">
          <h1>Our Mission</h1>
          <h2>
            To improve the health of people and community we serve and to manage
            illness and still expertise and comparison.
          </h2>
          <a href="#appointment" class="btn-get-started scrollto">
            Get Started
          </a>
        </div>
      </section>

      {/* <!-- ======= Why Us Section ======= --> */}
      <section id="why-us" class="why-us">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 d-flex align-items-stretch">
              <div class="content">
                <h3>Why Choose FAMILY HOSPITAL?</h3>
                <p>
                  Family Hospital Located in West Delhi,was started in the year
                  with an objective of strengthening medical care for all
                  sections of society by providing superior healthcare at an
                  affordable cost. The unmatched quality of care , and the
                  excellent clinical outcomes led to an ever increasing demand
                  for out and in patient treatment from not only the immediate
                  catchment but also from have flung areas. It is now a 50 bed
                  hospital with the highest level of technology and services
                  levels. Modular Operation theaters, highly efficient neonatal
                  and adult ICU, luxurious labour delivery room.
                </p>
                {/* <div class="text-center">
                  <a href="#" class="more-btn"
                    >Learn More <i class="bx bx-chevron-right"></i
                  ></a>
                </div>  */}
              </div>
            </div>
            <div class="col-lg-8 d-flex align-items-stretch">
              <div class="icon-boxes d-flex flex-column justify-content-center">
                <div class="row">
                  <div class="col-xl-4 d-flex align-items-stretch">
                    <div class="icon-box mt-4 mt-xl-0">
                      <FaRegSquareCheck size={35} color="#1977cc" />
                      <h4>Quality Policy</h4>
                      <p>
                        We are committed to provide quality healthcare services
                        by a team of competent and dedicated healthcare
                        professionals to the best satisfaction of our patients
                        and society. We shall strive to continuously improve the
                        healthcare services with innovative technology.
                      </p>
                    </div>
                  </div>
                  <div class="col-xl-4 d-flex align-items-stretch">
                    <div class="icon-box mt-4 mt-xl-0 d-none">
                      <IoIosCube size={35} color="#1977cc" />
                      <h4>Ullamco laboris ladore pan</h4>
                      <p>
                        Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt
                      </p>
                    </div>
                  </div>
                  <div class="col-xl-4 d-flex align-items-stretch">
                    <div class="icon-box mt-4 mt-xl-0 d-none">
                      <ImImages size={35} color="#1977cc" />
                      <h4>Labore consequatur</h4>
                      <p>
                        The <b>“Labore consequatur”</b> of a hospital
                        encompasses a range of essential activities. This
                        includes medical professionals providing patient care,
                        nurses administering treatments, administrative staff
                        managing records and schedules and support personnel
                        ensuring the smooth operation of facilities. It involves
                        a collaborative effort to maintain a clean, organized
                        and efficient environment to meet the healthcare needs
                        of patients. Additionally, ongoing training and research
                        contribute to the continuous improvement of medical
                        services within the hospital.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End .content--> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Why Us Section --> */}
      {/* <About/> */}
    </div>
  );
}

export default Hero