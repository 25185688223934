export const departmentData = [
    {
      id: "1",
      name: "Medicine Department",
    },
    {
      id: "2",
      name: "Gynecologist Department",
    },
    {
      id: "3",
      name: "Orthopedic Department",
    },
    {
      id: "4",
      name: "Pediatrics Department",
    },
    {
      id: "5",
      name: "ENT Care and Surgery Department",
    },
    {
      id: "6",
      name: "Neurology Department",
    },
    {
      id: "7",
      name: "Psychiatric Care Department",
    },
  ];