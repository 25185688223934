import { RiDoubleQuotesL } from "react-icons/ri";
import { RiDoubleQuotesR } from "react-icons/ri";

export const testimonialData = [
    {
      title: 'Akash',
      title_2:'',
      img:'/testimonials/testimonials.jpg',
      description:'I would definitely recommend this hospital. All the services were great from OPD to check-up. All the doctors, managers, and staff were very helpful and cooperative',
      icon: <RiDoubleQuotesL color="lightblue" size={25} />,
      icon_2: <RiDoubleQuotesR color="lightblue" size={25} />
    },
    {
      title: 'Divyanshu',
      title_2:'',
      img:'/testimonials/testimonials.jpg',
      description:'Hospital is extremely well maintained and disciplined. Each staff member of the hospital is very well behaved and sincere.',
      icon: <RiDoubleQuotesL color="lightblue" size={25} />,
      icon_2: <RiDoubleQuotesR color="lightblue" size={25} />
    },
    {
      title: 'Rekha',
      title_2:'',
      img:'/testimonials/testimonials.jpg',
      description:'Family Hospital is best in SahibPura experienced doctor practicing in a really noble way the entire staff deserves special mention for their services.',
      icon: <RiDoubleQuotesL color="lightblue" size={25} />,
      icon_2: <RiDoubleQuotesR color="lightblue" size={25} />
    },
    {
      title: 'देवराज',
      title_2:'',
      img:'/testimonials/testimonials.jpg',
      description:'धन्यवाद, "फैमिली हस्पताल" स्वास्थ्य के प्रति आपके अर्थक समर्पण के लिए | हमारा परिवार सुरक्षित है |',
      icon: <RiDoubleQuotesL color="lightblue" size={25} />,
      icon_2: <RiDoubleQuotesR color="lightblue" size={25} />
    },
    {
      title: 'Rakhi',
      title_2:'',
      img:'/testimonials/testimonials.jpg',
      description:'Good services and all staff are very friendly and supportive.',
      icon: <RiDoubleQuotesL color="lightblue" size={25} />,
      icon_2: <RiDoubleQuotesR color="lightblue" size={25} />
    },
    {
      title: 'Poonam',
      title_2:'',
      img:'/testimonials/testimonials.jpg',
      description:'Had a great experience and recovery was also fast.',
      icon: <RiDoubleQuotesL color="lightblue" size={25} />,
      icon_2: <RiDoubleQuotesR color="lightblue" size={25} />
    },
    {
      title: 'दयाराम',
      title_2:'',
      img:'/testimonials/testimonials.jpg',
      description:'तत्काल यक्तिगत देखभाल फैमिली हस्पताल को बाकियों से अलग करता है। चिकित्सा आवश्यकताओं के लिए एक विश्वसनीय चयन |',
      icon: <RiDoubleQuotesL color="lightblue" size={25} />,
      icon_2: <RiDoubleQuotesR color="lightblue" size={25} />
    },
    {
      title: 'Anupam',
      title_2:'',
      img:'/testimonials/testimonials.jpg',
      description:'Excellent and very capable doctor. I am really obliged that he has treated my mother and now she is fine. Thank you doctor.',
      icon: <RiDoubleQuotesL color="lightblue" size={25} />,
      icon_2: <RiDoubleQuotesR color="lightblue" size={25} />
    },
    {
      title: 'बलदेव',
      title_2:'',
      img:'/testimonials/testimonials.jpg',
      description:'असाधारण देखभाल और सहानुभूति भरा स्ट्राप "फैमिली हस्पताल" द्वारा प्रदान की गई सेवा के बहुत अभारी हूं |',
      icon: <RiDoubleQuotesL color="lightblue" size={25} />,
      icon_2: <RiDoubleQuotesR color="lightblue" size={25} />
    },
    {
      title: 'Ridhi',
      title_2:'',
      img:'/testimonials/testimonials.jpg',
      description:'Excellent and very capable doctor. I am really obliged that he has treated my mother and now she is fine. Thank you doctor.',
      icon: <RiDoubleQuotesL color="lightblue" size={25} />,
      icon_2: <RiDoubleQuotesR color="lightblue" size={25} />
    },
    {
      title: 'Vishal',
      title_2:'',
      img:'/testimonials/testimonials.jpg',
      description:'Brilliant care from all the staff.',
      icon: <RiDoubleQuotesL color="lightblue" size={25} />,
      icon_2: <RiDoubleQuotesR color="lightblue" size={25} />
    },
    {
      title: 'बृजेश',
      title_2:'',
      img:'/testimonials/testimonials.jpg',
      description:'मैं फैमिली हस्पताल की सिफारिश करता हूँ। एक आत्मसमर्पित अनुभव |',
      icon: <RiDoubleQuotesL color="lightblue" size={25} />,
      icon_2: <RiDoubleQuotesR color="lightblue" size={25} />
    },
    {
      title: 'Vishavjeet',
      title_2:'',
      img:'/testimonials/testimonials.jpg',
      description:'Thank you so much for the wonderful treatment and care.',
      icon: <RiDoubleQuotesL color="lightblue" size={25} />,
      icon_2: <RiDoubleQuotesR color="lightblue" size={25} />
    },
    {
      title: 'दर्शन',
      title_2:'',
      img:'/testimonials/testimonials.jpg',
      description:'हमारे परिवार को स्वास्थ्य सेवा के लिए फैमिली हस्पताल पर भरोसा है |',
      icon: <RiDoubleQuotesL color="lightblue" size={25} />,
      icon_2: <RiDoubleQuotesR color="lightblue" size={25} />
    },
    {
      title: 'Amar Nath',
      title_2:'',
      img:'/testimonials/testimonials.jpg',
      description:'Doctors work hard to make their patients feel comfortable.',
      icon: <RiDoubleQuotesL color="lightblue" size={25} />,
      icon_2: <RiDoubleQuotesR color="lightblue" size={25} />
    },
    {
      title: 'धवल',
      title_2:'',
      img:'/testimonials/testimonials.jpg',
      description:'आवश्यकता के समय फैमिली हस्पताल हमारा सहारा बना है |',
      icon: <RiDoubleQuotesL color="lightblue" size={25} />,
      icon_2: <RiDoubleQuotesR color="lightblue" size={25} />
    },
  ];