import React ,{useState} from 'react'
import './Contact.css'
import { CiMail } from "react-icons/ci";
import { CiMobile2 } from "react-icons/ci";
import { CiLocationOn  } from "react-icons/ci";

const Contact = () => {
  return (
    <div>  
        {/* <!-- ======= Contact Section ======= --> */}
    <section id="contact" class="contact">
      <div class="container">
        <div class="section-title">
          <h2>Contact</h2>
          <p>
            Reach Out to Us for Inquiries, Support, and Collaboration. Our
            Contact Information Ensures Seamless Communication.
          </p>
        </div>
      </div>

      <div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.5165836101955!2d77.08401381002133!3d28.644247175556746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0491e9c06225%3A0xc5aafde3f6b5954b!2sFamily%20Hospital!5e0!3m2!1sen!2sin!4v1705743021616!5m2!1sen!2sin"
        width="100%"
        height="350"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        >
       </iframe>
      </div>

      <div class="container d-none">
        <div class="row mt-5">
          <div class="col-lg-4">
            <div class="info">
            <div
                class="address"
              >
                <i className= "icon">
                  <CiLocationOn color='blue' size={25} />
                </i>

                <h4>Location:</h4>
                <p>
                  WZ 26A, New Sahib Pura, Opp. C.R.P.F Camp Tilak Nagar, New
                  Delhi, India, 110018
                </p>
              </div>

              <div class="email">
              <i class="bi bi-phone"> <CiMail color='blue' size={25} /></i>
                <h4>Email:</h4>
                <p>familyhospital18@gmail.com</p>
              </div>

              <div class="phone">
                <i class="bi bi-phone"> <CiMobile2 color='blue' size={25} /></i>
                <h4>Call:</h4>
                <p>+91- 9310206503,+91- 8448610029, +9111- 41688872</p>
              </div>
            </div>
          </div>

          <div class="col-lg-8 mt-5 mt-lg-0">
            <form
              action="forms/contact.php"
              method="post"
              role="form"
              class="php-email-form"
            >
              <div class="row">
                <div class="col-md-6 form-group">
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    id="name"
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    required
                  />
                </div>
              </div>
              <div class="form-group mt-3">
                <input
                  type="text"
                  class="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  required
                />
              </div>
              <div class="form-group mt-3">
                <textarea
                  class="form-control"
                  name="message"
                  rows="5"
                  placeholder="Message"
                  required
                ></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div class="text-center">
                <button type="submit">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    {/* <!-- End Contact Section --> */}
  
{/* //   <!-- End #main --> */}
{/* <Footer/> */}
  </div>
  )
}

export default Contact