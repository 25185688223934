import React from 'react';
import { useState } from 'react';
import { FaSquareFacebook } from 'react-icons/fa6';
import { FaSquareInstagram } from 'react-icons/fa6';
import { CiMail } from 'react-icons/ci';
import { CiMobile2 } from 'react-icons/ci';
import { IoMdMenu } from 'react-icons/io';
import { Link } from 'react-scroll';
import './Navbar.css';
import Icon from '../Icon/Icon';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };
  return (
    <div>
      {/* ======= Top Bar =======  */}
      <div id="topbar" className="d-flex align-items-center fixed-top">
        <div className="container d-flex justify-content-between">
          <div className="contact-info d-flex align-items-center">
            <CiMail color="blue" />
            &nbsp;
            <a href="Mailto:familyhospital18@gmail.com">
              familyhospital18@gmail.com
            </a>
            &nbsp;&nbsp;
            <CiMobile2 color="blue" />
            <a href="contactto:+91-9310206503, 8448610029, +9111141688872">+91-9310206503,+91- 8448610029, +9111-41688872</a>
          </div>
          <div className="d-none d-lg-flex social-links align-items-center">
            <Icon />
          </div>
        </div>
      </div>

      {/* <!-- ======= Header ======= */}

      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <h1 className="logo me-auto">
            <Link to="hero">
              {/* <img src=".\family-logo.jpg" alt="FamilyHospital Logo" /> */}
              <img src="./Family_Hospital_LOGO_final.png" style={{width: '350px'}} alt="FamilyHospital Logo" />
            </Link>
          </h1>

          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              <li>
                <Link
                  to="hero"
                  className="nav-link scrollto"
                  smooth={true}
                  duration={50}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="about"
                  className="nav-link scrollto"
                  smooth={true}
                  duration={50}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="services"
                  className="nav-link scrollto"
                  smooth={true}
                  duration={50}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="departments"
                  className="nav-link scrollto"
                  smooth={true}
                  duration={50}
                >
                  Departments
                </Link>
              </li>
              <li>
                <Link
                  to="doctors"
                  className="nav-link scrollto"
                  smooth={true}
                  duration={50}
                >
                  Doctors
                </Link>
              </li>
              <li>
                <Link
                  to="contact"
                  className="nav-link scrollto"
                  smooth={true}
                  duration={50}
                >
                  Contact
                </Link>
              </li>
            </ul>

            <div
              className="mobile-nav-toggle"
              onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
            >
              <IoMdMenu size={30} color="#2c4964" />
            </div>
          </nav>

          <Link
            to="appointment"
            className="appointment-btn scrollto"
            smooth={true}
            duration={50}
          >
            <span className="d-none d-md-inline">Make an</span> Appointment
          </Link>

          {/* For Mobile */}

          {isMobileMenuOpen && (
            <div className="mobile-menu-overlay">
              <div className="mobile-menu">
                <ul>
                  <li>
                    <Link
                      to="hero"
                      className="nav-link scrollto"
                      smooth={true}
                      duration={50}
                      onClick={closeMobileMenu}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="about"
                      className="nav-link scrollto"
                      smooth={true}
                      duration={50}
                      onClick={closeMobileMenu}
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="services"
                      className="nav-link scrollto"
                      smooth={true}
                      duration={50}
                      onClick={closeMobileMenu}
                    >
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="departments"
                      className="nav-link scrollto"
                      smooth={true}
                      duration={50}
                      onClick={closeMobileMenu}
                    >
                      Departments
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="doctors"
                      className="nav-link scrollto"
                      smooth={true}
                      duration={50}
                      onClick={closeMobileMenu}
                    >
                      Doctors
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </header>

      {/* <!-- End Header --> */}
    </div>
  );
};

export default Navbar;
