export const doctorData = [
    {
      id: "1",
      name: "Dr. Amitabh Kumar",
      value: "",
      departmentid: "",
    },
    {
      id: "2",
      name: "Dr. Veena Raina",
      value: "",
      departmentid: "2",
    },
    {
      id: "3",
      name: "Dr. V K Agarwal",
      value: "",
      departmentid: "1",
    },
    {
      id: "4",
      name: "Dr. Harvinder Singh",
      departmentid: "3",
    },
    {
      id: "5",
      name: "Dr. Paras Dev",
    },
    {
      id: "6",
      name: "Dr. Sharvari Gupta",
      departmentid: "4",
    },
    {
      id: "7",
      name: "Dr. Vinay",
      departmentid: "6",
    },
  ];
