import React, {useEffect} from 'react'
import {useState} from 'react'
import './Footer.css'
import { BsArrowUpShort } from "react-icons/bs";
import { MdChevronRight } from "react-icons/md";
import Icon from '../Icon/Icon';

const Footer = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
    {/* <!-- ======= Footer ======= --> */}
    <footer id="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 footer-contact">
              <h3>Family Hospital Pvt. Ltd.</h3>
              <p>
                WZ 26A, New Sahib Pura, 
                Opp. C.R.P.F Camp Tilak Nagar,<br />
                New Delhi, India, 110018 <br /><br />
                <strong>Phone:</strong> +91- 9310206503, +91- 8448610029, +9111- 41688872<br />
                <strong>Email:</strong> familyhospital18@gmail.com<br />
              </p>
            </div>

            <div class="col-lg-3 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                <MdChevronRight color='#1c84e3'/> <a href="#">Home</a>
                </li>
                <li>
                <MdChevronRight color='#1c84e3'/>
                  <a href="#about">About us</a>
                </li>
                <li>
                <MdChevronRight color='#1c84e3'/>
                  <a href="#services">Services</a>
                </li>
                <li>
                <MdChevronRight color='#1c84e3'/>
                  <a href="#doctors">Doctor</a>
                </li>
                <li>
                <MdChevronRight color='#1c84e3'/>
                  <a href="#">Privacy policy</a>
                </li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                <MdChevronRight color='#1c84e3'/> <a>Emergency Care</a>
                </li>
                <li>
                <MdChevronRight color='#1c84e3'/>
                  <a>Inpatient Care</a>
                </li>
                <li>
                <MdChevronRight color='#1c84e3'/>
                  <a>Outpatient Services</a>
                </li>
                <li><MdChevronRight color='#1c84e3'/> <a>Primary Care</a></li>
                <li>
                <MdChevronRight color='#1c84e3'/>
                  <a>Diagnostic Services</a>
                </li>
                <li>
                <MdChevronRight color='#1c84e3'/>
                  <a>Surgical Services</a>
                </li>
              </ul>
            </div>

            <div class="col-lg-4 col-md-6 footer-newsletter d-none">
              <h4>Join Our Newsletter</h4>
              <p>
                Tamen quem nulla quae legam multos aute sint culpa legam noster
                magna
              </p>
              <form action="" method="post">
                <input type="email" name="email" /><input
                  type="submit"
                  value="Subscribe"
                />
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="container d-md-flex py-4">
        <div class="me-md-auto text-center text-md-start">
          <div class="copyright">
            &copy; Copyright <strong><span>Family Hospital Pvt. Ltd</span></strong
            >. All Rights Reserved
          </div>
          <div class="credits">
            {/* <!-- All the links in the footer should remain intact. -->
            <!-- You can delete the links only if you purchased the pro version. -->
            <!-- Licensing information: https://bootstrapmade.com/license/ -->
            <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/medilab-free-medical-bootstrap-theme/ --> */}
            Designed by 
            <a href="https://www.macoinfotech.com/"> Maco Infotech Ltd.</a>
          </div>
        </div>
        <div class="social-links text-center text-md-right pt-3 pt-md-0">
          <Icon />
         {/* <div id="preloader"></div> */}
         {showButton && (
        <button className="back-to-top" onClick={scrollToTop}>
          <BsArrowUpShort color='white' />
        </button>
      )}
        </div>
      </div>
    </footer>
    {/* <!-- End Footer --> */}
    </div>
  )
}

export default Footer