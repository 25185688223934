import React, { useEffect } from "react";
import "./Testimonials.css";
// import styles bundle
import "swiper/css/bundle";
//import Swiper from 'swiper';
// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";
import { testimonialData } from "./TestimonialData";

const Testimonials = () => {
  useEffect(() => {
    // Initialize Swiper
    const swiper = new Swiper(".swiper", {
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      autoplay: {
        delay: 5000,
      },
    });
  }, []);

  return (
    <>
      {/* <!-- ======= Testimonials Section ======= --> */}
      <section id="testimonials" className="testimonials">
        <div className="container">
          <div className="section-title">
            <h2>Testimonial</h2>
          </div>
          <div
            className="testimonials-slider swiper"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="swiper-wrapper">
              {testimonialData.map((item, index) => (
                <>
                  <div className="swiper-slide" key={index}>
                    <div className="testimonial-wrap">
                      <div className="testimonial-item">
                        <img
                          src={item.img}
                          className="testimonial-img"
                          alt=""
                        />
                        <h3>{item.title}</h3>
                        <h4></h4>
                        <p>
                          {item.icon}
                          {item.description}
                          {item.icon_2}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>

          {/* <!-- If we need pagination --> */}
          <div class="swiper-pagination"></div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
