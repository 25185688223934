import React from 'react'
// import { FaTwitter } from "react-icons/fa";
// import { FaFacebookF } from "react-icons/fa";
// import { FaInstagram } from "react-icons/fa";
// import { FaLinkedinIn } from "react-icons/fa";

import "./Doctors.css"
import Navbar from '../Navbar/Navbar';
import FAQs from '../FAQs/FAQs';
const Doctors = () => {
  return (
    <div> 
        {/* <Navbar/> */}
        {/* <!-- ======= Doctors Section ======= --> */}
    <section id="doctors" class="doctors">
      <div class="container">
        <div class="section-title">
          <h2>Doctors</h2>
          <p>
            Unveiling the Profound Role of Doctors in Patient Care. Exploring
            Expertise, Compassion, and Innovation in the Medical Profession.
          </p>
        </div>

        <div class="row">

        <div class="col-lg-6 mt-4 mt-lg-0">
            <div class="member d-flex align-items-start">
              <div class="pic">
                <img
                  src="doctors/doctors-2.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>Dr. Veena Raina</h4>
                <span>MBBS MD</span>
                <span>Consultant Gynaecologist & Obstetrician</span>
                <span>Reg No. DMC 31733</span>
                {/* <div class="social">
                <a href=""><FaTwitter color='white'/></a>
                  <a href=""><FaFacebookF color='white'/></a>
                  <a href=""><FaInstagram color='white'/></a>
                  <a href=""> <FaLinkedinIn color='white'/></a>
                </div> */}
              </div>
            </div>
          </div>

          <div class="col-lg-6 mt-4">
            <div class="member d-flex align-items-start">
              <div class="pic">
                <img
                  src="doctors/doctors-6.jpeg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>Dr. Harvinder Singh</h4>
                <span>MBBS (UCMS) Gold Medalist</span>
                <span>MS (Ortho) (CIO, Safdarjung Hospital)</span>
                <span>Senior Consultant Orthopedic Surgeon</span>
                {/* <div class="social">
                <a href=""><FaTwitter color='white'/></a>
                  <a href=""><FaFacebookF color='white'/></a>
                  <a href=""><FaInstagram color='white'/></a>
                  <a href=""> <FaLinkedinIn color='white'/></a>
                </div> */}
              </div>
            </div>
          </div>

          <div class="col-lg-6 mt-4">
            <div class="member d-flex align-items-start">
              <div class="pic">
                <img
                  src="doctors/doctors-9.jpeg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>Dr. Andleeb Singh Sachdev</h4>
                <span>MBBS, MS (Ortho)</span>
                <span>Reg No. DMC/R/12513</span>
                {/* <div class="social">
                <a href=""><FaTwitter color='white'/></a>
                  <a href=""><FaFacebookF color='white'/></a>
                  <a href=""><FaInstagram color='white'/></a>
                  <a href=""> <FaLinkedinIn color='white'/></a>
                </div> */}
              </div>
            </div>
          </div>

          <div class="col-lg-6 mt-4">
            <div class="member d-flex align-items-start">
              <div class="pic">
                <img
                  src="doctors/doctors-3.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>Dr. V K Agarwal</h4>
                <span>MBBS, DNB (Internal Medicine)</span>
                <span>Consulting Cardio - Diabetics</span>
                <span>Physician &amp; Intensivist</span>
                <span>Reg No. DMC 67668</span>
                
                {/* <div class="social">
                <a href=""><FaTwitter color='white'/></a>
                  <a href=""><FaFacebookF color='white'/></a>
                  <a href=""><FaInstagram color='white'/></a>
                  <a href=""> <FaLinkedinIn color='white'/></a>
                </div> */}
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="member d-flex align-items-start">
              <div class="pic">
                <img
                  src="doctors/doctors-1.jpeg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>Dr. Amitabh Kumar</h4>
                <span>MBBS, DA</span>
                <span>Anesthesia</span>
                {/* <div class="social">
                  <a href=""><FaTwitter color='white'/></a>
                  <a href=""><FaFacebookF color='white'/></a>
                  <a href=""><FaInstagram color='white'/></a>
                  <a href=""> <FaLinkedinIn color='white'/></a>
                </div> */}
              </div>
            </div>
          </div>

          <div class="col-lg-6 mt-4">
            <div class="member d-flex align-items-start">
              <div class="pic">
                <img
                  src="doctors/doctors-5.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>Dr. Paras Dev</h4>
                <span>MD (Physician)</span>
                <span>DCH (Child Specialist)</span>
                <span>Regd No. 35021 (DMC)</span>
                {/* <div class="social">
                <a href=""><FaTwitter color='white'/></a>
                  <a href=""><FaFacebookF color='white'/></a>
                  <a href=""><FaInstagram color='white'/></a>
                  <a href=""> <FaLinkedinIn color='white'/></a>
                </div> */}
              </div>
            </div>
          </div>
          
          <div class="col-lg-6 mt-4">
            <div class="member d-flex align-items-start">
              <div class="pic">
                <img
                  src="doctors/doctors-8.jpeg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>Dr. Sharvari Gupta</h4>
                <span>MD (Pediatrics)</span>
                <span>Reg No. DMC 25903</span>
                {/* <div class="social">
                <a href=""><FaTwitter color='white'/></a>
                  <a href=""><FaFacebookF color='white'/></a>
                  <a href=""><FaInstagram color='white'/></a>
                  <a href=""> <FaLinkedinIn color='white'/></a>
                </div> */}
              </div>
            </div>
          </div>
          
          
          <div class="col-lg-6 mt-4">
            <div class="member d-flex align-items-start">
              <div class="pic">
                <img
                  src="doctors/doctors-7.jpeg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>Dr. Prakash </h4>
                <span>(MBBS MS General Surgeon)</span>
                <span></span>
                <span></span>
                {/* <div class="social">
                <a href=""><FaTwitter color='white'/></a>
                  <a href=""><FaFacebookF color='white'/></a>
                  <a href=""><FaInstagram color='white'/></a>
                  <a href=""> <FaLinkedinIn color='white'/></a>
                </div> */}
              </div>
            </div>
          </div>
          
          <div class="col-lg-6 mt-4">
            <div class="member d-flex align-items-start">
              <div class="pic">
                <img
                  src="doctors/doctors-10.jpeg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>Dr. Anshul</h4>
                <span>(MBBS MS General Surgeon)</span>
                {/* <span></span>
                <span></span> */}
                {/* <div class="social">
                <a href=""><FaTwitter color='white'/></a>
                  <a href=""><FaFacebookF color='white'/></a>
                  <a href=""><FaInstagram color='white'/></a>
                  <a href=""> <FaLinkedinIn color='white'/></a>
                </div> */}
              </div>
            </div>
          </div>
          
          <div class="col-lg-6 mt-4">
            <div class="member d-flex align-items-start">
              <div class="pic">
                <img
                  src="doctors/doctors-4.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>Dr. Vinay </h4>
                <span>(Neuro)</span>
                <span></span>
                <span></span>
                {/* <div class="social">
                <a href=""><FaTwitter color='white'/></a>
                  <a href=""><FaFacebookF color='white'/></a>
                  <a href=""><FaInstagram color='white'/></a>
                  <a href=""> <FaLinkedinIn color='white'/></a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <FAQs/> */}
    {/* <!-- End Doctors Section --> */}
    </div>
  )
}

export default Doctors