import { useState } from "react";
import React from "react";
import "./Appointment.css";
import { departmentData } from "./DepartmentData";
import { doctorData } from "./DoctorData";

const Appointment = () => {
  const handleDepartmentChange = (event) => {
    const arr = doctorData.filter(
      (obj) => obj.departmentid === event.target.value
    );
    console.log(arr);
    setDoctorFilterData(arr);
  };

  const [doctorFilterData, setDoctorFilterData] = useState(doctorData);

  return (
    <div>
      {/* <!-- ======= Appointment Section ======= --> */}
      <section id="appointment" class="appointment section-bg">
        <div class="container">
          <div class="section-title">
            <h2>Make an Appointment</h2>
            <p>
              Kindly specify your desired date and time for the appointment.
              We'll do our best to accommodate your preferences. If you have any
              specific requirements, feel free to let us know
            </p>
          </div>

          <form
            action="forms/appointment.php"
            method="post"
            role="form"
            class="php-email-form"
          >
            <div class="row">
              <div class="col-md-4 form-group">
                <input
                  type="text"
                  name="name"
                  class="form-control"
                  id="name"
                  placeholder="Your Name"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars"
                />
                <div class="validate"></div>
              </div>
              <div class="col-md-4 form-group mt-3 mt-md-0">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  data-rule="email"
                  data-msg="Please enter a valid email"
                />
                <div class="validate"></div>
              </div>
              <div class="col-md-4 form-group mt-3 mt-md-0">
                <input
                  type="tel"
                  class="form-control"
                  name="phone"
                  id="phone"
                  placeholder="Your Phone"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars"
                />
                <div class="validate"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 form-group mt-3">
                <input
                  type="date"
                  name="date"
                  class="form-control datepicker"
                  id="date"
                  placeholder="Appointment Date"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars"
                />
                <div class="validate"></div>
              </div>
              <div class="col-md-4 form-group mt-3">
                <select
                  name="department"
                  id="department"
                  class="form-select"
                  onChange={handleDepartmentChange}
                >
                  <option selected disabled hidden>
                    Select Department
                  </option>
                  {departmentData.map((departmentitem, index) => (
                    <option key={departmentitem.id} value={departmentitem.id}>
                      {departmentitem.name}
                    </option>
                  ))}
                </select>
                <div class="validate"></div>
              </div>
              
              <div class="col-md-4 form-group mt-3">
                <select name="doctor" id="doctor" class="form-select">
                  <option selected disabled hidden>
                    Select Doctor
                  </option>
                  {doctorFilterData.map((item) => (
                    <option value={item.value} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <div class="validate"></div>
              </div>
            </div>

            <div class="form-group mt-3">
              <textarea
                class="form-control"
                name="message"
                rows="5"
                placeholder="Message (Optional)"
              ></textarea>
              <div class="validate"></div>
            </div>
            <div class="mb-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">
                Your appointment request has been sent successfully. Thank you!
              </div>
            </div>
            <div class="text-center">
              <button type="submit">Make an Appointment</button>
            </div>
          </form>
        </div>
      </section>
      {/* <!-- End Appointment Section --> */}
    </div>
  );
};

export default Appointment;
