export default function Icon() {
  return (
    <>
      <a
        href="https://facebook.com/FamilyHospitalTilakNagar/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src=".\facebook.png" alt="" />
      </a>
      <a
        href="https://www.instagram.com/familyhospitalpvtltd/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src=".\insta-icon.png" alt="" className="insta"/>
      </a>
    </>
  );
}
