import { AboutData } from './AboutData'; 
import React from 'react'
import './About.css'


const About = () => {
  return (
    <div>
      <section id="about" className="about">
        <div className="container-fluid">
          <div className="section-title">
            <h2>About Us</h2>
          </div>
          <div className="row">
            <div class="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative">
              <a
                href="/video/v1.mp4"
                class="glightbox play-btn mb-4"
                target="_blank"
             
                
              ></a>
              <a
                href="/video/v2.mp4"
                class="glightbox play-btn mb-4"
                target="_blank"
              ></a>
            </div>
            <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
              <h3>Family Hospital</h3>
              <p>
                We are committed to providing compassionate and comprehensive
                healthcare services to our community. As a general hospital, we
                take pride in offering personalized care with a focus on patient
                well-being. Our dedicated team of healthcare professionals is
                here to serve you with expertise and warmth.
              </p>

              {AboutData.map((item, index) => (
                <>
                  <div className="a-icon-box" key={index}>
                    <div className="a-icon">{item.icon}</div>
                    <h4 className="a-title">
                      <a href="">{item.title}</a>
                    </h4>

                    {/* Integrity */}
                    {index === 2 ? (
                      <>
                        <p className="a-description">
                          “Ensuring Integrity in healthcare: Our Commitment to
                          Trust and Excellence”
                          <br />
                          Welcome to Family Hospital where integrity is the
                          cornerstone of our healthcare philosophy. We are
                          dedicated to upholding the highest standards of
                          integrity in every aspect of our services. Here’s how
                          we prioritize integrity and delivering compassionate
                          and quality healthcare.
                        </p>
                        <ul className="a-description">
                          <li>Patient-Centered Care</li>
                          <li>Medical Ethics</li>
                          <li>Clinical Excellence</li>
                          <li>Transparent Communication</li>
                          <li>Data Security and Privacy</li>
                        </ul>
                        <p className="a-description">
                          At Family Hospital integrity is not just a value, it’s
                          a way of life. We invite you to experience health-care
                          that prioritizes trust, transparency and the highest
                          ethical standards. Your health and well-being are our
                          top priorities.
                        </p>
                      </>
                    ) : (
                      <p className="a-description">{item.description}</p>
                    )}

                    {/* Continuous Improvement */}
                    {index === 4 ? (
                      <>
                        <p className="a-description">
                          “Discover our commitment to continuous improvement at
                          Family Hospital. We strive to enhance patient care
                          through”
                        </p>
                        <ul className="a-description">
                          <li>Patient Feedback and Testimonials</li>
                          <li>
                            Quality Improvement Initiatives-highlight ongoing
                            quality improvements projects
                          </li>
                          <li>Staff Training and Development</li>
                          <li>Cutting-Edge technology</li>
                          <li>Patient Education Resources</li>
                          <li>Community Engagement</li>
                          <li>Accreditations and Certifications</li>
                          <li>Regular Blog update</li>
                          <li>Patient Safety Initiatives</li>
                          <li>Feedback Mechanism</li>
                          <li>Transparent Reporting</li>
                          <li>Sustainability Practices</li>
                        </ul>
                        <p className="a-description">
                          Remember to regularly update the content to keep it
                          relevant and reflective of the hospital’s ongoing
                          commitment to continuous improvement in healthcare
                          delivery.
                        </p>
                      </>
                    ) : null}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
